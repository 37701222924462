import httpUtil from "@/utils/httpUtil";

//会员列表
export const selectAllMemberLink = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/selectAllMemberLink", data) }

//会员等级切换
export const linkMemberLevel = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/linkMemberLevel", data) }

//会员等级
export const selectAllMemberLevel = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/selectAllMemberLevel", data) }

//添加修改会员等级
export const setMemberLevel = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/setMemberLevel", data) }

//增加积分
export const addPointRecord = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/addPointRecord", data) }

//修改会员状态
export const updateLink = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/updateLink", data) }

//查询会员积分记录
export const selectAllPointRecord = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/selectAllPointRecord", data) }

//任务列表
export const selectMemberTaskLink = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/selectMemberTaskLink", data) }

//任务类型
export const selectMemberTask = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/selectMemberTask", data) }

//新增会员任务
export const LinkMemberTask = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/LinkMemberTask", data) }

//会员订单
export const getMemberOrderList = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/getMemberOrderList", data) }

//会员订单详情
export const getMemberOrderById = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/getMemberOrderById", data) }

// 分销
//获得会员分销明细列表
export const getMemberDistributorRecordList = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/getMemberDistributorRecordList", data) }

//获得会员分销明细详情
export const getMemberDistributorRecord = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/getMemberDistributorRecord", data) }

//设置会员分销明细
export const setMemberDistributorRecord = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/setMemberDistributorRecord", data) }

//获得会员特殊二级分销列表
export const getMemberDistributorSpecialList = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/getMemberDistributorSpecialList", data) }

//获得会员特殊二级分销信息
export const getMemberDistributorSpecial = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/getMemberDistributorSpecial", data) }

//设置会员特殊二级分销
export const setMemberDistributorSpecial = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/setMemberDistributorSpecial", data) }

//设置会员特殊二级分销 多个
export const setMemberDistributorSpecialAll = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/member/setMemberDistributorSpecialAll", data) }

//获取登录过社团端9/园区端8 小程序的用户
export const getUserListByOpenIdLink = (data = {}, type = 8) => { data.accountType = type; return httpUtil.post("/api/crmPc/association/getUserListByOpenIdLink", data) }


/**设置分销等级赠送 */
export const setMemberLevelGive = (params = {}, type = 8) => { params.accountType = type; return httpUtil.post("/api/crmPc/member/setMemberLevelGive", params) };

/**获得分销等级赠送列表 */
export const getMemberLevelGiveList = (params = {}, type = 8) => { params.accountType = type; return httpUtil.post("/api/crmPc/member/getMemberLevelGiveList", params) };

/**设置分销等级拥有 */
export const setMemberLevelHave = (params = {}, type = 8) => { params.accountType = type; return httpUtil.post("/api/crmPc/member/setMemberLevelHave", params) };

/**获得分销等级拥有列表 */
export const getMemberLevelHaveList = (params = {}, type = 8) => { params.accountType = type; return httpUtil.post("/api/crmPc/member/getMemberLevelHaveList", params) };

/**获得分销等级赠送 */
export const getMemberLevelGive = (params = {}, type = 8) => { params.accountType = type; return httpUtil.post("/api/crmPc/member/getMemberLevelGive", params) };

/**获得分销等级拥有 */
export const getMemberLevelHave = (params = {}, type = 8) => { params.accountType = type; return httpUtil.post("/api/crmPc/member/getMemberLevelHave", params) };